export default defineNuxtRouteMiddleware(async () => {
  const router = useRouter()

  const accountStore = useAccountStore()
  const user = computed(() => accountStore.user)
  const auth0User = computed(() => accountStore.authUser)

  if (!auth0User.value.user.isEmailVerified) {
    return await router.push('/verify')
  }

  if (!user.value?.isSuperAdmin) {
    await router.push('/generate')
  }
})
